import { get } from '@/utils/request'

const analysisApi = {
  videoAnalysisApi: '/api/content/video/analysis'
}

// 获取用户的视频观看记录
export function getUserWatch() {
  return get(analysisApi.videoAnalysisApi + '/watch')
}

export function getUserWatchRegion() {
  return get(analysisApi.videoAnalysisApi + '/watch/region')
}
