<template>
  <el-container>
    <el-row>
      <el-col class="card-style" :md="12">
        <el-card>
          <div id="chart1" style="width: 600px;height:400px;" />
        </el-card>
      </el-col>
      <el-col class="card-style" :md="12">
        <el-card>
          <div id="chart2" style="width: 600px;height:400px;" />
        </el-card>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import { getUserWatch, getUserWatchRegion } from '@/api/analysis'

export default {
  name: 'PostAnalysis',
  data() {
    return {
      barChartNames: [],
      barChartData: [],
      pieChartNames: [],
      pieChartData: [],
      chartOption: null
    }
  },
  created() {
    // 页面数据加载的等待状态栏
    this.loading = this.$loading({
      body: true,
      lock: true,
      text: '数据拼命加载中,(*╹▽╹*)',
      spinner: 'el-icon-loading'
    })
    this.getData()
  },
  methods: {
    getData() {
      this.getUserWatchWrapper()
      this.getUserWatchRegionWrapper()
    },
    async getUserWatchWrapper() {
      await getUserWatch().then((resp) => {
        if (resp.code === 0) {
          this.barChartNames = resp.data[0].split(',')
          this.barChartData = resp.data[1].split(',')
          this.drawBarChart()
          const myChart = this.$echarts.init(document.getElementById('chart1'))
          // setOption 是 merge，而非赋值, setOption 支持 notMerge 参数, 值为 true 时重新渲染
          myChart.setOption(this.chartOption, true)
          this.loading.close()
        }
      })
    },
    drawBarChart() {
      this.chartOption = {
        title: {
          text: '观看时长',
          subtext: '最近一周',
          x: 'center',
          y: 'top',
          textAlign: 'center'
        },
        tooltip: {},
        xAxis: {
          data: this.barChartNames
        },
        yAxis: {},
        series: [{
          name: '时长',
          type: 'bar',
          data: this.barChartData
        }]
      }
    },
    async getUserWatchRegionWrapper() {
      await getUserWatchRegion().then((resp) => {
        const pieNames = resp.data[0].split(',')
        const pieNumbers = resp.data[1].split(',')
        pieNames.forEach((item, index) => {
          this.pieChartData.push({
            name: item,
            value: parseInt(pieNumbers[index])
          })
        })
        this.drawPieChart()
        this.loading.close()
      })
    },
    drawPieChart() {
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('chart2'))
      const option = {
        title: {
          text: '分区占比',
          subtext: '视频分区',
          x: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c}次 ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: this.pieChartData
        },
        series: [
          {
            name: '时长',
            type: 'pie',
            radius: '55%',
            data: this.pieChartData,
            roseType: 'angle',
            itemStyle: {
              normal: {
                shadowBlur: 200,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>

<style scoped lang="scss">
.el-container {
  width: 100%;
  height: 100%;
}
.el-container {
  animation: leftMoveIn .7s ease-in;
}

@keyframes leftMoveIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.card-style {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
  padding-right: 3px;
}
</style>
